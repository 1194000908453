
.btn-bnn-default {
    padding: 1.25rem 0 !important
    font-weight: bold !important
    font-size: 0.875rem !important
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
    height 20px !important
}

text-white {
  color: #fff
}

.text-btn
    color $color-btn-base !important

.text-small {
  font-size: 0.675rem !important
}

.text-large {
  font-size: 1.3rem !important
}

.font-bold {
  font-weight: bold !important
}

.font-normal {
  font-weight: normal !important
}
